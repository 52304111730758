<template>
  <div style="width: 100%" id="printReport">
    <header
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      "
    >
      <img
        src="@/assets/logoBlue.png"
        alt="[logo]"
        style="width: 600px; padding: 20px"
      />
      <div>
        <h5>
          {{ `RELATÓRIO DE ENCOMENDAS - ${agencia}` }}
        </h5>
        <p>
          Período de: {{ convertDate(dateStart) }} à {{ convertDate(dateEnd) }}
        </p>
      </div>
    </header>

    <table
      v-for="(item, ind) in items"
      :key="ind"
      style="text-align: center; width: 100%; margin-top: 40px"
    >
      <thead>
        <tr>
          <th
            style="padding: 5px; display: flex; justify-content: space-between"
          >
            <div>
              {{ item.nome }}
            </div>
            <div>Total: {{ convertCurrent(item.total) }}</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <table
              class="table"
              style="
                border-collapse: collapse;
                border: 1px solid rgb(214, 214, 214);
                border-top: 0;
                text-align: center;
                width: 100%;
              "
            >
              <thead>
                <tr
                  style="
                    border: 1px solid rgb(214, 214, 214);
                    text-align: center;
                  "
                >
                  <th
                    v-for="(header, ih) in headers"
                    :key="ih"
                    style="
                      border: 1px solid rgb(214, 214, 214);
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    {{ header.text.toUpperCase() }}
                  </th>
                </tr>
              </thead>
              <div v-if="item.dados.length">
                <tbody v-for="(data, idx) in item.dados" :key="idx">
                  <tr style="border: 1px solid rgb(214, 214, 214)">
                    <td style="border: 1px solid rgb(214, 214, 214)">
                      {{ data.cod_barras }}
                    </td>
                    <td style="border: 1px solid rgb(214, 214, 214)">
                      {{ convertDateTimeNew(data.data_hora_emissao) }}
                    </td>
                    <td style="border: 1px solid rgb(214, 214, 214)">
                      {{ data.agencia_criacao }}
                    </td>
                    <td style="border: 1px solid rgb(214, 214, 214)">
                      {{ data.agencia_entrega }}
                    </td>
                    <td style="border: 1px solid rgb(214, 214, 214)">
                      {{ data.operador_criacao }}
                    </td>
                    <td style="border: 1px solid rgb(214, 214, 214)">
                      {{ data.operador_entrega }}
                    </td>
                    <td style="border: 1px solid rgb(214, 214, 214)">
                      {{ data.a_pagar == 1 ? "Sim" : "Não" }}
                    </td>
                    <td style="border: 1px solid rgb(214, 214, 214)">
                      {{ convertCurrent(data.valor_total) }}
                    </td>
                  </tr>
                </tbody>
              </div>
              <div v-else>
                <tbody>
                  <td colspan="10">Nenhum dado disponível</td>
                </tbody>
              </div>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import mixin from "@/mixins";
export default {
  props: ["items", "headers", "dateStart", "dateEnd", "agencia"],
  mixins: [mixin],
  create() {
    this.mixin;
  },
};
</script>
